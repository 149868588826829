import React from "react";
import ReactDOM from "react-dom";
// import { Provider } from "react-redux"
// import { KeycloakProvider } from "@react-keycloak/web"

import App from "./App";
// import { configureStore } from "./redux/store"
import * as serviceWorker from "./serviceWorker";
import { ReactQueryConfigProvider } from "react-query";
// import { keycloak } from "./keycloak"

// const store = configureStore()
const queryConfig = { refetchAllOnWindowFocus: false };

ReactDOM.render(
  <ReactQueryConfigProvider config={queryConfig}>
    {/* <Provider store={store}> */}
    <App />
    {/* </Provider> */}
  </ReactQueryConfigProvider>,
  document.getElementById("root")
);
/*
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: https://bit.ly/CRA-PWA
 */
serviceWorker.unregister();
