import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import main from "./views";
// import app from "./views/app";
import error from "./views/error";

function App({ locale, account }) {
  return (
    <div className="h-100">
      <React.Fragment>
        <Router>
          <Switch>
            {/* <Route path="/app" component={app} /> */}
            <Route path="/error" exact component={error} />
            <Route path="/" component={main} />
            <Redirect to="/error" />
          </Switch>
        </Router>
      </React.Fragment>
    </div>
  );
}

export default App;
