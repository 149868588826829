import React, { Component } from "react";
import { Row, Button } from "reactstrap";
import { Colxx } from "../components/common/CustomBootstrap";
// import IntlMessages from "../helpers/IntlMessages";
// import "./user/login.scss"
import AppLayout from "../layout/AppLayout";
import "./error.scss";

class Error extends Component {
  // componentDidMount() {
  //   document.body.classList.add("background");
  // }
  // componentWillUnmount() {
  //   document.body.classList.remove("background");
  // }
  render() {
    return (
      <AppLayout>
        <main>
          <div className="container">
            <Row className="h-100">
              <Colxx xxs="12" md="10" className="mx-auto my-auto">
                <div className="error-page text-center mt-5">
                  <div className="">
                    <p className="h2 font-weight-bold">
                      SORRY, PAGE NOT FOUND!
                    </p>
                    <p className="white">
                      The request page doesn’t exist. If you entered a web
                      address, please check it was correct. Please go to the
                      Fonepay Homepage by clicking the button below.
                    </p>
                    <Button
                      href="/"
                      color="primary"
                      outline
                      className="btn-shadow"
                    >
                      Fonepay Home
                    </Button>
                  </div>
                  <div className="">
                    <img src="/img/error-bg.png" alt="" />
                    {/* <p className="display-1 font-weight-bold mb-5">404</p> */}
                  </div>
                </div>
              </Colxx>
            </Row>
          </div>
        </main>
      </AppLayout>
    );
  }
}
export default Error;
