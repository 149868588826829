import "./index.css";
import "bootstrap/dist/css/bootstrap.css";

import "core-js/fn/array/flat-map";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import {
  faBed,
  faBars,
  faGripHorizontal
} from "@fortawesome/free-solid-svg-icons";

library.add(fab, faBed, faBars, faGripHorizontal);

require("./assets/sass/_style.scss");

require("./AppRenderer");
