import classnames from "classnames";
import React from "react";
import "./SearchBox.scss";
import { Form, Formik } from "formik";
import { useHistory } from "react-router-dom";
import { FormikTextFieldGroup } from "../formik/FormikTextFieldGroup";
import routes from "../../constants/routes";
import { useQueryGet } from "../../utils/url";

function SearchBox() {
  const history = useHistory();

  let query = useQueryGet();
  const name = query.get("name");

  return (
    <Formik
      enableReinitialize
      initialValues={{
        search: name
      }}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        history.push({
          pathname: `${routes["offers"]}`,
          search:
            "?" +
            new URLSearchParams({
              name: values.search
            }).toString()
        });
      }}
    >
      {({ isSubmitting, errors }) => (
        <Form
          className={classnames({
            "search-box": true,
            "is-submitting": isSubmitting
          })}
        >
          <FormikTextFieldGroup name="search" label="" autoComplete="off" />
          {/* <form className="search-box my-2 my-lg-0">
                <input
                  className="form-control mr-sm-2"
                  type="search"
                  placeholder=""
                  aria-label="Search"
                />
                <button
              className="btn btn-outline-success my-2 my-sm-0"
              type="submit"
            >
              Search
            </button>
              </form> */}
        </Form>
      )}
      {/* ))} */}
    </Formik>
  );
}

export default SearchBox;
