import React, { useState } from "react";
import { Button } from "reactstrap";
import "./SwitchToApp.scss";
// import { Link } from "react-router-dom";

function SwitchToApp(props) {
  const [showCard, setShowCard] = useState(true);
  return (
    <>
      {showCard && (
        <div className="switch-to-app d-none ">
          <div className="cross-btn position-relative">
            <span className="btn-bg position-absolute">
              <Button color="link" onClick={() => setShowCard(false)}>
                <img src="/img/icons/quit.svg" alt="" />
              </Button>
            </span>
          </div>
          <div className="container-fluid">
            <div className="body">
              <div className="title">Fonepay Offer is better on the App</div>
              <div className="text">
                <p>
                  Login to view the Fonepay points and more ways to View the
                  offers.
                </p>
              </div>
              <a href="https://offersapps.fonepay.com/welcome">
                <Button color="danger">Switch To App</Button>
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default SwitchToApp;
