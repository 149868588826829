// import React, { Component } from "react";
// import { Redirect } from "react-router-dom";

// class Main extends Component {
//   render() {
//     return <Redirect to="/app" />;
//   }
// }
// export default Main;

import React, { Component, lazy, Suspense } from "react";
import { Route, withRouter, Switch, Redirect } from "react-router-dom";
import routes from "../constants/routes";

import AppLayout from "../layout/AppLayout";
// import BlankPage from "./blank-page";

const Homepage = lazy(() => import("./homepage"));
const Banners = lazy(() => import("./banners"));
const Offers = lazy(() => import("./offers"));
const OfferDetails = lazy(() => import("./offerDetails"));
const Collections = lazy(() => import("./collections"));
const Merchants = lazy(() => import("./merchants"));
const FonepayPointsLoyality = lazy(() =>
  import("./pages/fonepay-points-loyalty")
);
const FonepayChalleges = lazy(() => import("./pages/fonepay-challenges"));

class App extends Component {
  render() {
    // const { match } = this.props;

    return (
      <AppLayout>
        <Suspense fallback={<div className="loading" />}>
          <Switch>
            {/* <Redirect exact from={`${match.url}/`} to={`${match.url}/home`} /> */}

            <Route path={`/banners`} component={Banners} />
            <Route path={`/offers`} component={Offers} />
            <Route
              path={`${routes["offerDetails"]}/:id`}
              component={OfferDetails}
            />
            <Route path={`/collections`} component={Collections} />
            <Route path={`/merchants`} component={Merchants} />
            <Route
              path={`/fonepay-points-loyalty`}
              component={FonepayPointsLoyality}
            />
            <Route path={`/fonepay-challenges`} component={FonepayChalleges} />

            <Route exact path={`/`} component={Homepage} />

            <Redirect to="/error" />
          </Switch>
        </Suspense>
      </AppLayout>
    );
  }
}

export default withRouter(App);
