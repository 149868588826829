import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import TopNav from "../containers/navs/Topnav";
import Footer from "../containers/navs/Footer";
import SwitchToApp from "../components/SwitchToApp";

class AppLayout extends Component {
  render() {
    const { containerClassnames } = this.props;
    return (
      <div id="app-container" className={containerClassnames}>
        <TopNav history={this.props.history} />
        <main className="main-container" style={{ minHeight: "500px" }}>
          <div className="container-fluid-">{this.props.children}</div>
        </main>
        <Footer />
        <SwitchToApp />
      </div>
    );
  }
}
export default withRouter(AppLayout);
