import React, { useState } from "react";
import { Collapse, Navbar, NavbarToggler, Nav, NavItem } from "reactstrap";

import { Link } from "react-router-dom";
import SearchBox from "../../components/common/SearchBox";
import "./Topnav.scss";

const Topnav = props => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <Navbar
      className="navbar-fixed justify-content-end"
      color="light"
      light
      expand="md"
    >
      <Link className="navbar-brand" to="/">
        <img src="/img/fonepay-logo.svg" alt="" />
      </Link>

      <NavbarToggler onClick={toggle} />
      <Collapse isOpen={isOpen} navbar>
        <Nav className="mr-auto" navbar>
          <NavItem>
            <Link className="nav-link" to="/">
              Home
            </Link>
          </NavItem>
          <NavItem>
            <Link className="nav-link" to="/components">
              Components
            </Link>
          </NavItem>
          <NavItem>
            <Link className="nav-link" to="/offers/default?nearby=true">
              Nearby Offers
            </Link>
          </NavItem>
          <NavItem>
            <Link
              className="nav-link"
              to="/offers/default?category=112bcad2-8b41-4b6a-ae1c-71a697fef15f"
            >
              Offers
            </Link>
          </NavItem>
          <NavItem>
            <Link
              className="nav-link"
              to="/offers/0df49df8-a202-4114-8d45-6a59dcac4851"
            >
              Offer Details
            </Link>
          </NavItem>
          <NavItem>
            <Link
              className="nav-link"
              to="/merchants/f7681568-728b-49e9-b4da-301939cb03c0"
            >
              Merchant Details
            </Link>
          </NavItem>
        </Nav>
      </Collapse>

      <SearchBox />

      <style jsx="true">
        {`
          .navbar-toggler,
          .navbar-expand-md .navbar-collapse {
            display: none !important;
          }
        `}
      </style>
    </Navbar>
  );
};

export default Topnav;
