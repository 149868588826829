import React from "react";
import { FormGroup, Label, Input, FormFeedback } from "reactstrap";
import { Field, ErrorMessage, connect, getIn } from "formik";

export const FormikTextFieldGroup = connect(props => {
  const { label, name, feedbackMessage, ...restProps } = props;

  const error = getIn(props.formik.errors, name);
  const touched = getIn(props.formik.touched, name);

  return (
    <FormGroup>
      <Label for={name}>{label}</Label>
      <Field name={name}>
        {({ field }) => (
          <Input
            {...restProps}
            {...field}
            value={field.value ? field.value : ""}
            noValidate
            invalid={Boolean(feedbackMessage) || Boolean(touched && error)}
          />
        )}
      </Field>
      {feedbackMessage ? (
        <FormFeedback>{feedbackMessage}</FormFeedback>
      ) : (
        <ErrorMessage name={name} component={FormFeedback} />
      )}
    </FormGroup>
  );
});
