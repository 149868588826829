import { useLocation } from "react-router-dom";

export function useQueryGet() {
  return new URLSearchParams(useLocation().search);
}

export function newSearchParams(search, newParams) {
  const newURLSearchParams = new URLSearchParams(search.toString());
  Object.entries(newParams).forEach(([key, valueFn]) => {
    const newValue = valueFn(newURLSearchParams.get(key));
    if (newValue) {
      newURLSearchParams.set(key, newValue);
    } else {
      newURLSearchParams.delete(key);
    }
  });
  return newURLSearchParams;
}
